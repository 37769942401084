import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { objStr } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useRolesList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Название', sortable: true },
    { key: 'description', label: 'Описание', sortable: true },
    {
      key: 'permissions',
      label: 'Доступ',
      sortable: false,
      formatter: objStr,
    },
    { key: 'createdAt', label: 'Создано', sortable: true },
    { key: 'updatedAt', label: 'Обновлено', sortable: true },
    { key: 'actions', label: 'Действия' },
  ]
  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const roleOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchData()
  })
  const q = (val, joiner) => {
    const where = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tableColumns.length; i++) {
      const column = tableColumns[i].key
      if (column !== 'actions') where.push(`(${column},like,~${val}~)`)
    }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }
  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('app-role/fetchRoles', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + sortBy.value,
        _where: (roleFilter.value !== null ? `(id,eq,${roleFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, roleFilter.value !== null) : ''),
        _fields: '-',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Groups',
      })
      .then(response => {
        const { records, total } = response.data
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < records.length; i++) {
          if (Object.hasOwnProperty.call(records[i], 'permissions')) {
            records[i].permissions = JSON.parse(records[i].permissions)
          }
        }
        const roles = records
        // console.log(records)

        callback(roles)
        totalRoles.value = total
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles list',
            text: error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // getting all roles name id for v-select
  store
    .dispatch('app-role/fetchRoles', {
      _fields: 'id,name',
      _tableconstruction_version: 'vertri',
      _zeTable: 'Groups',
    })
    .then(response => {
      const { records } = response.data
      roleOptions.value = records
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoleVariant = role => {
    if (role === 'staff') return 'primary'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveRoleIcon = role => {
    if (role === 'staff') return 'UserIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveRoleStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    roleOptions,

    resolveRoleVariant,
    resolveRoleIcon,
    resolveRoleStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
  }
}
